import { registerSW } from "virtual:pwa-register";

const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {
    console.log("service worker updated");
    updateSW();
  },
  onOfflineReady() {
    console.log("service worker registered");
  },
});
